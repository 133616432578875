import React, { useState } from 'react';
import { Link } from 'gatsby';
import 'whatwg-fetch';
import SEO from '../components/seo';
import { escapeHTMLEntities } from '../utils/parse';

import Layout from '../components/layout';

import './search.scss';

const API_URL = 'https://skin-deep.goodpraxis.coop/wp-json/wp/v2/search';
const PER_PAGE = 9;

const mapApiDataToPosts = (data: any) => {
  const { title, url } = data;
  // eslint-disable-next-line
  const { featured_media_src_url: imageUrl } = data._embedded.self[0];
  const parts = url.split('/').slice(-3);
  let slug = `/articles/${parts[1]}`;
  if (Number.isNaN(+parts[0])) {
    parts[0] += 's';
    slug = `/${parts.join('/')}`;
  }
  return { title, slug, imageUrl };
};

const Events = () => {
  const [isFinished, setIsFinished] = useState(true);
  const [userSearchQuery, setUserSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [noResults, setNoResults] = useState(false);
  const [noMoreResults, setNoMoreResults] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(true);

  const performSearch = (query: string, page = 1, append = false) => {
    setIsFirstTime(false);
    setNoResults(false);
    setCurrentPage(page);
    setIsFinished(false);
    setUserSearchQuery(query);
    fetch(`${API_URL}?search=${query}&per_page=${PER_PAGE}&_embed&page=${page}`)
      .then((response) => response.json()).then((data) => {
        setNoResults(data.length === 0);
        setNoMoreResults(data.length < PER_PAGE);
        const mainResults = append ? searchResults : [];
        setSearchResults(mainResults.concat(data.map(mapApiDataToPosts)));
        setIsFinished(true);
      });
  };

  const fetchMoreResults = () => {
    performSearch(userSearchQuery, currentPage + 1, true);
  };

  return (
    <>
      <SEO title="Search" />
      <Layout categories="Search">
        <div className="full-page-width-wrapper search-page">
          <div className="search-results-top">
            <div className="search-results-top-headline">Search for:</div>
            <form
              className="search-form"
              onSubmit={(ev) => {
                ev.preventDefault();
                if (userSearchQuery) {
                  performSearch(userSearchQuery);
                }
              }}
            >
              <input
                disabled={!isFinished}
                type="text"
                className="search-input"
                value={userSearchQuery}
                onChange={(ev) => setUserSearchQuery(ev.target.value)}
              />
              <button
                disabled={!isFinished || userSearchQuery.length < 2}
                type="submit"
                className="search-form-button"
              >
                Search

              </button>
            </form>
          </div>
          { isFirstTime ? (
            <p>Use the above to search for any Skin Deep article on the site.</p>
          ) : ''}
          {(isFinished || currentPage > 1) && searchResults.length > 0 ? (
            <>
              <div className="search-results post-list">
                {searchResults.map(({
                  slug, date, title, imageUrl,
                }) => (
                  <div className="article-link" key="slug">
                    <Link to={slug}>
                      <div className="article-link-image">
                        <img src={imageUrl} alt="" />
                      </div>
                      <div className="article-link-date">{date}</div>
                      <div className="article-link-title">{escapeHTMLEntities(title)}</div>
                    </Link>
                  </div>
                ))}
              </div>
              { !noMoreResults
                ? (
                  <div className="search-load-more-wrapper">
                    <button
                      type="button"
                      className="search-load-more-button"
                      disabled={!isFinished}
                      onClick={() => fetchMoreResults()}
                    >
                      {isFinished ? 'Load more' : 'Loading...'}
                    </button>
                  </div>
                ) : '' }
            </>
          ) : ''}
          { noResults ? (
            <>
              <h3 className="search-results-headline">No results</h3>
              <p>We could not find any results matching your search query.</p>
            </>
          ) : ''}
        </div>
      </Layout>
    </>
  );
};

export default Events;
